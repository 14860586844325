import React, { useState } from "react";
import Propic from "../../Assets/images/blackAvatar.png";
import { MdCircle, MdKeyboardArrowRight } from "react-icons/md";
import { IoIosArrowUp, IoIosInformationCircleOutline, IoMdArrowRoundBack } from "react-icons/io";
import { Dropdown, Modal, Button, Input, Avatar } from "antd";
import { IoClose, IoLockClosedOutline } from "react-icons/io5";
import { PiSealCheckFill } from "react-icons/pi";
import ModalComponent from "../../Compoments/Modal";
import ExtractedData from "./fragment/extracted-data";
import { BlacklistUser } from "./fragment/blacklist-user";
import { RemoveUserFromBlacklist } from "./fragment/remove-blacklist";

export default function DataPage({ data }) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [extractedData, setExtractedData] = useState({});
  const [activeModal, setActiveModal] = useState(0);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [selectedCredential, setSelectedCredential] = useState(null);
  const [activeStatus, setActiveStatus] = useState("");
  const [reasonClicks, setReasonClicks] = useState({});
  // const [showlacklistRemovalModal, setBlacklistRemovalModal] = useState(false)

  const handleBlacklistClick = () => {};

  const openMessagemodal = () => {
    setMessageModalVisible(true);
  };
  const closeMessageModal = () => {
    setMessageModalVisible(false);
  };

  const handleDropdownVisibleChange = (flag) => {
    setDropdownVisible(flag);
  };

  const OpenModal = (credential) => {
    setSelectedCredential(credential);
    setModal(true);
  };

  const CloseModal = () => {
    setModal(false);
    setSelectedCredential(null);
  };

  const OpenEditModal = () => {
    setEditModalVisible(true);
  };

  const CloseEditModal = () => {
    setEditModalVisible(false);
  };

  const handleUpdateDetails = () => {
    setSuccessModalVisible(true);
    CloseEditModal(); // Optionally close the edit modal
  };

  const handleReasonClick = (reason) => {
    setReasonClicks((prev) => ({
      ...prev,
      [reason]: !prev[reason],
    }));
  };

  const blacklistReasons = [
    "Age",
    "Duplicate",
    "Forged ID",
    "Regulations Violations",
    "Different Doc",
    "Unaccepted region",
    "ID mismatch",
    "Others",
  ];

  const rejectReasons = [
    "Bad Photo",
    "Data not Readeable",
    "Additional page Required",
    "Additional Checks",
    "Custom",
    "Incorrect TIN",
  ];

  const modals = [
    <BlacklistUser {...{ setShowDialog, showDialog }} />,
    <ExtractedData {...{ setShowDialog, showDialog, data: extractedData }} />,
    <RemoveUserFromBlacklist {...{ setShowDialog, showDialog }} />
  ];

  // console.log(data, "data")

  const items = [
    {
      label: (
        <div className="flex flex-col gap-2 py-3" onClick={(e) => e.stopPropagation()}>
          {activeStatus === "blacklist" && (
            <div>
              <button
                className="w-full flex gap-2 items-center hover:text-blue-500 font-bold"
                onClick={() => setActiveStatus("")}
              >
                <IoMdArrowRoundBack />
                Blacklist
              </button>
              <small className="pl-3">Select Reason</small>
              <div className="mt-3 flex flex-wrap gap-3">
                {blacklistReasons.map((item) => (
                  <small key={item} onClick={() => handleReasonClick(item)} className="cursor-pointer">
                    {reasonClicks[item] ? (
                      <span className="flex items-center bg-red-100 text-red-600 py-1 px-2 rounded-[15px]">
                        {item} <IoClose size={20} />
                      </span>
                    ) : (
                      <span className="flex items-center gap-2 bg-gray-200 py-1 px-2 rounded-[15px]">{item}</span>
                    )}
                  </small>
                ))}
              </div>
              <Button className="py-5 w-full mt-10" type="primary">
                Done
              </Button>
            </div>
          )}
          {activeStatus === "" && (
            <>
              {!activeStatus.includes("blacklist") && (
                <div className="flex flex-col gap-2">
                  <button
                    className="w-full flex items-center justify-between hover:text-blue-500 font-bold"
                    onClick={() => {
                      // setActiveStatus("blacklist");
                      // setReasonClicks({});
                      setShowDialog(true);
                      setActiveModal(0);
                    }}
                  >
                    Blacklist
                    <MdKeyboardArrowRight />
                  </button>
                </div>
              )}
              {!activeStatus.includes("rejected") && (
                <div className="flex flex-col gap-2">
                  <button
                    className="w-full flex items-center justify-between hover:text-blue-500 font-bold"
                    onClick={() => {
                      setActiveStatus("rejected");
                      setReasonClicks({});
                    }}
                  >
                    Rejected
                    <MdKeyboardArrowRight />
                  </button>
                </div>
              )}
            </>
          )}
          {activeStatus === "rejected" && (
            <div className="w-full">
              <button
                className="w-full flex gap-2 items-center hover:text-blue-500 font-bold"
                onClick={() => setActiveStatus("")}
              >
                <IoMdArrowRoundBack />
                Rejected
              </button>
              <small className="pl-2">Select Reason</small>
              <div className="mt-3 flex flex-wrap gap-3">
                {rejectReasons.map((item) => (
                  <small key={item} onClick={() => handleReasonClick(item)} className="cursor-pointer">
                    {reasonClicks[item] ? (
                      <span className="flex items-center bg-violet-100 text-violet-600 py-1 px-2 rounded-[15px]">
                        {item} <IoClose size={20} />
                      </span>
                    ) : (
                      <span className="flex items-center gap-2 bg-gray-200 py-1 px-2 rounded-[15px]">{item}</span>
                    )}
                  </small>
                ))}
              </div>
              <Button className="py-5 w-full mt-10" type="primary">
                Done
              </Button>
            </div>
          )}
        </div>
      ),
      key: "1",
    },
  ];

  return (
    <div className="bg-gray-100">
      <div className="px-5 py-3 border-b-[2px] border-b-gray-300 bg-white flex  items-center justify-between gap-5">
        <div className="flex items-center gap-5">
          <Avatar src={data?.data?.profile?.user__image} size="large">
            {data?.data?.profile?.user__first_name?.charAt(0)}
            {data?.data?.profile?.user__last_name?.charAt(0)}
          </Avatar>
          <div className="font-bold">
            {data?.data?.profile?.user__first_name}
            {data?.data?.profile?.user__last_name}
          </div>
        </div>
        <div className="flex items-center gap-3">
          <span>Status:</span>
          <small className="flex items-center gap-2 py-1 px-2 text-blue-600 bg-blue-100 rounded-[15px]">
            <MdCircle size={10} />
            {data?.data?.profile?.user__verification_status}
          </small>
        </div>
      </div>
      <div className="px-5 pb-5 h-[calc(100vh-220px)]">
        {data?.data?.docs?.length >= 1 && (
          <section>
            {/* Content */}
            <div className={`md:flex justify-between py-5 gap-3`}>
              <div>
                {/* Overlay text */}
                {data?.data?.profile?.blacklisted && (
                  <div className="absolute h-fit w-fit inset-0 md:left-[400px] left-[100px] md:top-[300px] top-[600px] flex flex-col gap-3 items-center justify-center z-10">
                    <IoLockClosedOutline />
                    <small className="text-center">
                      Remove this user from your blacklist <br />
                      to uncover additional information.
                    </small>
                  </div>
                )}
                <span className="text-gray-500">Credentials (in-use)</span>
                <div
                  className={`relative md:flex md:flex-row  gap-4 flex flex-col mb-3 justify-between py-5 md:gap-3 ${
                    data?.data?.profile?.blacklisted ? "filter blur-sm" : ""
                  }`}
                >
                  {data?.data?.docs?.map((item) => (
                    <div
                      key={item.id}
                      onClick={data?.data?.profile?.blacklisted ? CloseModal : () => OpenModal(item)}
                      className={`hover:shadow flex flex-col gap-3 p-2 md:w-[165px] w-full rounded-md bg-white h-fit ${
                        data?.data?.profile?.blacklisted ? "cursor-not-allowed select-none" : "cursor-pointer"
                      }`}
                    >
                      <div className="md:w-[150px] w-full h-fit">
                        <img src={item?.image1} alt="" />
                      </div>
                      <strong>{item?.name}</strong>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div>
              <button
                onClick={data?.data?.profile?.blacklisted ? closeMessageModal : openMessagemodal}
                className={`py-2 px-3 bg-blue-600 cu text-white rounded-md ${
                  data?.data?.profile?.blacklisted
                    ? "filter blur-sm cursor-not-allowed select-none"
                    : ""
                }`}
              >
                Message User
              </button>
            </div> */}
            </div>
          </section>
        )}
        <section className="">
          <h1 className="text-gray-500 my-3">User Information</h1>
          <div className="bg-white rounded-md p-4 w-full">
            <div className="pb-3 border-b w-full font-bold">Personal Details (Preview)</div>
            <div className="md:flex justify-between">
              <div className="flex flex-col gap-3 py-5">
                <div className="flex items-center gap-1">
                  <span className="font-bold">Full Name:</span> {data?.data?.profile?.user__first_name}
                  {data?.data?.profile?.user__middle_name}
                  {data?.data?.profile?.user__last_name}
                </div>
                <div className="flex items-center gap-1">
                  <span className="font-bold">Email: </span> {data?.data?.profile?.user__user__email}
                </div>
                {/* {data?.data?.profile?.blacklisted && (
                  <div className="absolute h-fit w-fit inset-0 md:left-[300px] left-[80px] md:top-[700px] top-[1350px] flex flex-col gap-3 items-center justify-center z-10">
                    <IoLockClosedOutline />
                    <small className="text-center">
                      Remove this user from your blacklist <br />
                      to uncover additional information.
                    </small>
                  </div>
                )} */}
                <div
                  className={`relative flex flex-col gap-3 ${
                    data?.data?.profile?.blacklisted && "filter blur-sm cursor-not-allowed select-none"
                  }`}
                >
                  <div className="flex items-center gap-1">
                    <span className="font-bold">Phone Number:</span> {data?.data?.profile?.user__phone ?? "N/A"}
                  </div>
                  <div className="flex items-center gap-1">
                    <span className="font-bold">BVN:</span> N/A
                  </div>
                  <div className="flex items-center gap-1 text-s">
                    <span className="font-bold">Address (Residential):</span> {data?.data?.profile?.user__bvn ?? "N/A"}
                  </div>
                </div>
              </div>
              <div>
                <img alt="" className="md:w-[150px] w-full" src={data?.data?.profile?.user__image ?? Propic} />
            {data && !data?.data?.profile.blacklisted &&    <button
                  onClick={() => {
                    setShowDialog(true);
                    setActiveModal(0);
                  }}
                  className="border h-[50px] rounded-[8px] m-4 border-red-500 px-4 text-red-500"
                >
                  Blacklist User
                </button>}
                {data && data?.data?.profile.blacklisted &&    <button
                  onClick={() => {
                    setShowDialog(true);
                    setActiveModal(2);
                  }}
                  className="border h-[50px] rounded-[8px] m-4 border-blue-500 px-4 text-blue-500"
                >
                  Remove from blacklist
                </button>}
              </div>
            </div>
          </div>
        </section>
        <section className="py-3">
          <h1 className="text-gray-500 my-3">Extracted Data</h1>
          <div className="bg-white rounded-md p-4 w-full">
            <div className="pb-3 border-b w-full font-bold">ID details (Preview)</div>
            <div className={`${data?.data?.profile?.blacklisted && "filter blur-sm cursor-not-allowed select-none"}`}>
              {data?.data?.ocr_details?.map((item) => (
                <div className="flex justify-between w-full">
                  <div>
                    {Object.entries(item?.ocr_details).map(([key, value], index) => (
                      <div key={index} className="my-2">
                        <strong>{key}:</strong> <span className="text-[14px]">{value || "N/A"}</span>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={() => {
                      setExtractedData(item);
                      setActiveModal(1);
                      setShowDialog(true);
                    }}
                    className="border border-primary text-primary h-[50px] rounded-[8px] m-4 px-4"
                  >
                    Edit Extracted Data
                  </button>
                </div>
              ))}
            </div>
          </div>
        </section>
        <div className="flex items-center gap-3 bg-blue-50 shadow rounded-md py-5 px-2 w-full">
          <IoIosInformationCircleOutline style={{ color: "blue" }} />
          <small>
            This information was extracted from the Identification card the Vendor uploaded, Please compare with User
            Provided data above.
          </small>
        </div>
      </div>
      <Modal open={modal} onCancel={CloseModal} footer={null} width={650}>
        {selectedCredential && (
          <div className="flex flex-col gap-3">
            <strong>{selectedCredential.type}</strong>
            <small>status:</small>
            <div className="md:flex">
              <div>
                <small className="text-blue-500">Front view</small>
                <div className="w-[300px] border-dashed border-blue-500 rounded-lg p-1">
                  <img src={selectedCredential.image1} alt="" />
                </div>
              </div>
              <div>
                <small className="text-blue-500">Back view</small>
                <div className="w-[300px] border-dashed border-blue-500 rounded-lg p-1">
                  <img src={selectedCredential.image2} alt="" />
                </div>
              </div>
            </div>
            <div className="md:flex flex flex-col items-center gap-3 md:w-[50%] w-full">
              <Dropdown
                overlayStyle={{ width: 300 }}
                trigger={["click"]}
                menu={{ items }}
                visible={dropdownVisible}
                onVisibleChange={handleDropdownVisibleChange}
              >
                <button className="flex w-full items-center justify-center gap-2 py-3 bg-gray-200 flex-1 rounded-md">
                  Update Status <IoIosArrowUp />
                </button>
              </Dropdown>
              <button className="py-3 w-full bg-blue-500 text-white flex-1 rounded-md">Save Status</button>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        width={400}
        open={editModalVisible}
        onCancel={CloseEditModal}
        footer={[
          <div className="w-full flex flex-col gap-3 mt-5">
            <Button className="py-5 flex w-full" key="save" type="primary" onClick={handleUpdateDetails}>
              Update Details
            </Button>
            <Button className="py-5 flex w-full text-red-600" key="cancel" onClick={CloseEditModal}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <div className="flex flex-col gap-4">
          <strong className="text-xl">Edit Extracted Data</strong>
          {/* Add form fields or data to edit here */}
          <div className="flex flex-col">
            <label className="font-bold">Full Name</label>
            <input className="border rounded py-3 px-2" type="text" defaultValue="Gabriel Frank Mike" />
          </div>
          <div className="flex flex-col">
            <label className="font-bold">Email</label>
            <input className="border rounded py-3 px-2" type="email" defaultValue="gabrielmike@gmail.com" />
          </div>
          <div className="flex flex-col">
            <label className="font-bold">Phone Number</label>
            <input className="border rounded py-3 px-2" type="text" defaultValue="+2348189972990" />
          </div>
          <div className="flex flex-col">
            <label className="font-bold">Address</label>
            <input
              className="border rounded py-3 px-2"
              type="text"
              defaultValue="07, Peace Estate CMD road, Magodo Lagos"
            />
          </div>
        </div>
      </Modal>
      {/* Success Modal */}
      <Modal
        open={successModalVisible}
        onCancel={() => setSuccessModalVisible(false)}
        footer={null}
        centered
        width={350}
      >
        <div className="py-3 gap-3 flex flex-col items-center justify-center text-center">
          <div className="text-blue-600">
            <PiSealCheckFill size={80} />
          </div>
          <h2 className="text-lg font-bold mb-2">You successfully updated the extracted Details</h2>
          <small>Now, you just need to wait for the information to sync.</small>
          <Button className="w-full py-5" type="primary" onClick={() => setSuccessModalVisible(false)}>
            Proceed to Dashboard
          </Button>
          <small className="">
            If you have any questions, contact us at <span className="text-blue-600 font-bold">sales@zinid.com</span>
          </small>
        </div>
      </Modal>
      <Modal title="Send a message" open={messageModalVisible} onCancel={closeMessageModal} footer={null} width={400}>
        <small>Talk to Active users </small>
        <Input.TextArea placeholder="Enter your message here" rows={4} />
        <div className="mt-4 flex w-full">
          <Button className="w-full py-5" type="primary">
            Send Messaage
          </Button>
        </div>
      </Modal>
      <ModalComponent
        width={500}
        closeIcon={false}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        component={modals[activeModal]}
      />
    </div>
  );
}
