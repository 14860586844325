import { createBrowserRouter } from "react-router-dom";
import OnboardingLayout from "./Pages/Onboarding/Account/OnboardingLayout";
import BusinessVerificationLayout from "./Pages/Onboarding/BusinessVerification/BusinessVerificationLayout";
import Profile from "./Dashboard/Users/Profile";

const router = createBrowserRouter([
  {
    path: "",
    lazy: async () => {
      let { Homepage } = await import("./Pages/Homepage/Homepage");
      return { Component: Homepage };
    },
  },
  {
    path: "about-us",
    lazy: async () => {
      let { AboutUs } = await import("./Pages/About/About");
      return { Component: AboutUs };
    },
  },
  {
    path: "services",
    lazy: async () => {
      let { Services } = await import("./Pages/Services/Services");
      return { Component: Services };
    },
  },
  {
    path: "contact-us",
    lazy: async () => {
      let { ContactUs } = await import("./Pages/Contact/Contact");
      return { Component: ContactUs };
    },
  },
  {
    path: "subscriptions",
    lazy: async () => {
      let { Subscriptions } = await import("./Subscription/Subscriptions");
      return { Component: Subscriptions };
    },
  },
  {
    path: "documentation",
    lazy: async () => {
      let { Documentation } = await import("./Pages/Documentation/Documentation");
      return { Component: Documentation };
    },
  },
  {
    path: "single-sign-on",
    lazy: async () => {
      let { SingleSignOn } = await import(
        "./Pages/Onboarding/Account/SingleSignOn/SingleSignOn"
      );
      return { Component: SingleSignOn };
    },
  },
  {
    path: "onboarding",
    element: <OnboardingLayout />,
    children: [
      {
        path: "sign-in",
        lazy: async () => {
          let { SignIn } = await import(
            "./Pages/Onboarding/Account/SignIn/SignIn"
          );
          return { Component: SignIn };
        },
      },
      // {
      //   path: "auth",
      //   lazy: async () => {
      //     let { SingleSignOnV2 } = await import(
      //       "./Pages/Onboarding/Account/SingleSignOn/SingleSignOnV2"
      //     );
      //     return { Component: SingleSignOnV2 };
      //   },
      // },
      {
        path: "sign-up",
        lazy: async () => {
          let { SignUp } = await import(
            "./Pages/Onboarding/Account/SignUp/SignUp"
          );
          return { Component: SignUp };
        },
      },
      {
        path: "reset-password",
        lazy: async () => {
          let { ResetPassword } = await import(
            "./Pages/Onboarding/Account/ResetPassword/ResetPassword"
          );
          return { Component: ResetPassword };
        },
      },
      {
        path: "otp",
        lazy: async () => {
          let { OTP } = await import("./Pages/Onboarding/Account/OTP/OTP");
          return { Component: OTP };
        },
      },
      {
        path: "create-password",
        lazy: async () => {
          let { CreatePassword } = await import(
            "./Pages/Onboarding/Account/CreatePassword/CreatePassword"
          );
          return { Component: CreatePassword };
        },
      },
      {
        path: "invitee",
        lazy: async () => {
          let { Invitee } = await import(
            "./Pages/Onboarding/Account/Invitee/Invitee"
          );
          return { Component: Invitee };
        },
      },
    ],
  },
  {
    path: "auth",
    children: [
      {
      path: "sign-in",
      lazy: async () => {
        let { SingleSignOnV2 } = await import(
          "./Pages/Onboarding/Account/SingleSignOn/SingleSignOnV2"
        );
        return { Component: SingleSignOnV2 };
      }},
      {
        path: "verify-with-zinid",
        lazy: async () => {
          let { VerifyWithZinId } = await import(
            "./Pages/Verification/VerifyWithZinId"
          );
          return { Component: VerifyWithZinId };
        }},
    ]
  },
  {
    path: "business-onboarding",
    element: <BusinessVerificationLayout />,
    children: [
      {
        path: "liveness-check",
        lazy: async () => {
          let { LivenessCheckBase } = await import(
            "./Pages/Onboarding/BusinessVerification/LivenessCheck/LivenessCheck"
          );
          return { Component: LivenessCheckBase };
        },
      },
      {
        path: "information",
        lazy: async () => {
          let { Information } = await import(
            "./Pages/Onboarding/BusinessVerification/Information/Information"
          );
          return { Component: Information };
        },
      },
      {
        path: "verification",
        lazy: async () => {
          let { Verification } = await import(
            "./Pages/Onboarding/BusinessVerification/Verification/Verification"
          );
          return { Component: Verification };
        },
      },
      {
        path: "director",
        lazy: async () => {
          let { Director } = await import(
            "./Pages/Onboarding/BusinessVerification/Director/Director"
          );
          return { Component: Director };
        },
      },
      {
        path: "data-preference",
        lazy: async () => {
          let { DataPreference } = await import(
            "./Pages/Onboarding/BusinessVerification/DataPreference/DataPreference"
          );
          return { Component: DataPreference };
        },
      },
    ],
  },
  {
    path: "overview",
    lazy: async () => {
      let { Overview } = await import("./Dashboard/Overview/Overview");
      return { Component: Overview };
    },
  }, 
  {
    path: "users",
    lazy: async () => {
      let { UsersPage } = await import("./Dashboard/Users");
      return { Component: UsersPage };
    },
  },
  {
    path: "members",
    lazy: async () => {
      let { Members } = await import("./Dashboard/Members/Members");
      return { Component: Members };
    },
  },
  {
    path: "app-integration",
    lazy: async () => {
      let { AppIntegration } = await import("./Dashboard/AppIntegration/AppIntegration");
      return { Component: AppIntegration };
    },
  },
  {
    path: "users/profile",
    element: <Profile />,
  },
  {
    path: "api-documentation",
    lazy: async () => {
      let { ApiDocumentation } = await import("./Dashboard/ApiDocumentation/ApiDocumentation");
      return { Component: ApiDocumentation };
    },
  },
  {
    path: "settings",
    lazy: async () => {
      let { Settings } = await import("./Dashboard/Settings/Settings");
      return { Component: Settings };
    },
  },
  {
    path: "billing",
    children: [
      {
        index: true,
        lazy: async () => {
          let { Billing } = await import("./Dashboard/Billing/Billing");
          return { Component: Billing };
        },
      },
      {
        path: "invoice",
        lazy: async () => {
          let { ViewInvoice } = await import("./Dashboard/Billing/actions/ViewInvoice");
          return { Component: ViewInvoice };
        },
      },
      {
        path: "receipt",
        lazy: async () => {
          let { Receipt } = await import("./Dashboard/Billing/actions/Receipt");
          return { Component: Receipt };
        },
      },
    ],
  },
]);

export default router;
